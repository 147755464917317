import React from "react";
import { Link } from "gatsby";
import logoVerticalUrl from "src/images/logo-lelivebar-vertical-red-min.svg";

/**
 * Composant d'affichage du logo principal
 * @returns {JSX.Element}
 * @constructor
 */

export default function LogoVertical() {
  return (
    <div className={"logo-vertical"}>
      <Link
        to="/"
        title={"Accéder à la page d'accueil"}
      >
          <img src={logoVerticalUrl} width="230" height="213" alt="" />
      </Link>
    </div>
  );
}
