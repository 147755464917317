import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql, withPrefix } from "gatsby";
import logoLelivebarSmall from "src/images/icon-lelivebar.svg";
import logoImageOG from "src/images/lelivebar-og-min.jpg";

const SEO = ({ seoProps }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);
  const {
        defaultTitle,
        defaultDescription,
        siteUrl,
        urlFacebook
  } = site.siteMetadata;

  const seo = {
    title: seoProps.title || defaultTitle,
    description: seoProps.description || defaultDescription,
    image: logoLelivebarSmall,
    url: pathname === "/" ? siteUrl + "" : siteUrl + pathname,
    urlFacebook: urlFacebook,
  };

  let urlPrint = "/css/print.css";

  return (
    <Helmet title={seo.title}>
      <link rel="canonical" href={seo.url} />
      <link rel="icon" href={seo.image} />
      <link
        rel="stylesheet"
        type="text/css"
        href={withPrefix(urlPrint)}
        media={"print"}
      />

      {/* OpenGraph tags / Facebook */}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={logoImageOG} />}
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="fr_FR" />
      <meta property="og:site_name" content="Le live bar" />

      {/* General tags */}
      <meta name="description" content={seo.description} />
      <meta name="image" content={logoImageOG} />
      <meta name="author" content="Flavien Fergeau" />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
      />
      <meta name="robots" content="index, follow" />
      <meta
        name="googlebot"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <meta
        name="bingbot"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />

      <html lang="fr" />
    </Helmet>
  );
};
export default SEO;
SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    urlFacebook: PropTypes.string,
};
SEO.defaultProps = {
    title: "",
    description: "",
    url: "",
    urlFacebook: "",
};
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        urlFacebook
      }
    }
  }
`;
