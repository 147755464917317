import React from "react";

/**
 * Composant permettant de créer un container centrer
 * @param children
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
export default function LayoutCentered({
  children,
  className = "",
  size = "large",
}) {
  return (
    <div className={className + " container-size container-size-" + size}>
      {children}
    </div>
  );
}
