import React from "react";
import LayoutCentered from "src/components/layoutcentered";
import { Link, graphql, useStaticQuery } from "gatsby";
import logoVerticalUrl from "src/images/logo-lelivebar-vertical-red-min.svg";

/**
 * Composant permettant de créer le footer du site numeko
 * @returns {JSX.Element}
 * @constructor
 */
export default function Footer() {
  const { site } = useStaticQuery(query);

  console.log("test");
  console.log(site.siteMetadata.urlAudit);
  console.log(site.siteMetadata);
  return (
    <footer id={"footer"}>
      <LayoutCentered>
        <div className={"informations"}>
          <div className={"informations--left"}>
            <div className={"access-to"}>
              <h1>Accès</h1>
              <address>
                Adresse&nbsp;: <a href={"https://www.google.com/maps/search/?api=1&query=6+Rue+de+Strasbourg,+44000+Nantes"}>6 Rue de Strasbourg</a>
                <br/>(Ici entrée PMR)
                <br/>Courrier&nbsp;: 7 rue des états <br/>
                44000 Nantes
              </address>
            </div>
            <div className={"contact-us"}>
              <h1>
                <Link to="/contact/" title={"Accéder à la page contact du site le live bar"}>Contactez-nous&nbsp;!</Link>
              </h1>
              <ul className={"social-button"}>
                <li>
                  <a href={site.siteMetadata.urlFacebook} title={"Accéder à la page facebook le live bar"}>
                    <span className={"icon-lelivebar-facebook2"}/>
                  </a>
                </li>
                <li>
                  <a
                      href={"mailto:" + site.siteMetadata.mailContact}
                      title={"Nous écrire par email"}
                  >
                    <div className={"icon-lelivebar-icon-mail"} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className={"schedule"}>
            <h1>Horaires</h1>
            <div className={"schedule-container"}>
              <div className={"schedule-container--left"}>
                <h2>sept - mai</h2>
                <p>
                  Du lundi au jeudi<br/>
                  17h - 2h
                </p>
                <p>
                  Du vendredi au dimanche<br/>
                  14h - 2h
                </p>
              </div>
              <div className={"schedule-container--right"}>
                <h2>juin - août</h2>
                <p>
                  Du lundi au dimanche<br/>
                  11h - 2h
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={"others"}>
          <img src={logoVerticalUrl} width="230" height="213" alt="" />
          <div className={"others-legalnotice"}>
            <span>Le Live Bar à été écoconçu en 2021 par </span>
            <a className={"numeko"} href={site.siteMetadata.urlNumeko}
               title={"Accéder au site internet de la société Numeko"}
            >NUMEKO</a>
            <span> - Tous droits réservés - </span>
            <Link className={"legal-notices"} to="/mentions-legales/">
              Mentions&nbsp;légales
            </Link>
            <span> - </span>
            <a className={"rapport-audit"} href={site.siteMetadata.urlAudit}
               title={"Accéder au rapport audit de performance et d'écoconception du site Lelivebar en pdf"}
            >Audit Ecoconception</a>
            <p>
              L'éco-conception permet la conception d’application moins énergivores, plus respectueuses de l'environnement et&nbsp;accessibles&nbsp;à&nbsp;tous.
            </p>
          </div>
        </div>
      </LayoutCentered>
    </footer>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        urlFacebook
        urlNumeko
        urlAudit
        mailContact
      }
    }
  }
`;
