import React from "react";
import {Link} from "gatsby";
import BurgerMenu from "src/components/burgermenu";
import LogoHorizontal from "src/components/logoHorizontal";
import LogoVertical from "src/components/logoVertical";

/**
 * Composant d'affichage du menu
 * @returns {JSX.Element}
 * @constructor
 */
export default class Menu extends React.Component {
  render() {
    return (
      <nav id="top-menu-nav">
        <ul>
          <li className={"item-pagename underline"}>
            {this.props.pageProps.menuPageTitle}
          </li>
          <li className={"item-link item-home"}>
            <Link
                to="/"
                activeClassName="active"
            >
              Accueil
            </Link>
          </li>
          <li className={"item-facebook"}>
            <a href={this.props.data.site.siteMetadata.urlFacebook} title={"Accéder à la page facebook le live bar"}>
              <span className={"icon-lelivebar-facebook2"} />
            </a>
          </li>
          <li className={"item-link item-menu"}>
            <Link
              id={"anchor-expertises"}
              to="/carte"
              activeClassName="active"
            >
              La carte
            </Link>
          </li>
          <li className={"item-logo"}>
            <LogoHorizontal />
            <LogoVertical />
          </li>
          <li className={"item-link item-events"}>
            <Link to="/evenements" activeClassName="active">
              Évènements
            </Link>
          </li>
          <li className={"item-contact"}>
            <Link className={"ripple"} to="/contact" activeClassName="active">
              Contact
            </Link>
          </li>
          <li className={"item-burgermenu"}>
            <BurgerMenu />
          </li>
        </ul>
      </nav>
    );
  }
}
