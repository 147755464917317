import React from "react";
import Menu from "src/components/menu";
import {graphql, useStaticQuery} from "gatsby";
import LayoutCentered from "src/components/layoutcentered";
// import MenuAccessibility from "src/components/menuaccessibility";

/**
 * Composant permettant de créer l'entête (Header)
 * @param pageProps
 * @returns {JSX.Element}
 * @constructor
 */
export default function Header({pageProps = {}}) {
    const data = useStaticQuery(graphql`
     query {
        site {
          siteMetadata {
            urlFacebook
          }
        }
     }
   `)

  return (
    <header>
        {/*<MenuAccessibility />*/}
        <LayoutCentered className={"menu-container"}>
            <Menu data={data} pageProps={pageProps}/>
        </LayoutCentered>
    </header>
  );
}
