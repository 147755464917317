import React from "react";
import { isDesktop } from "react-device-detect";

/**
 * Composant permettant d'afficher un burger menu
 * @returns {JSX.Element}
 * @constructor
 */
export default class BurgerMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: true };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.body.style.overflow = "";
  }

  handleMouseDown(ev) {
    // Override click event to not fire href url
    ev.preventDefault();
    ev.stopPropagation();
  }

  handleClick(ev) {
    // Open burger menu
    this.setState({ active: !this.state.active });

    // Display nav menu if burger menu is open
    let $burgerMenu = document.getElementsByClassName("burger-menu")[0];
    let $header = document.getElementsByTagName("header")[0];
    let $nav = document.getElementById("top-menu-nav");
    let $body = ev.view.document.body;

    if ($burgerMenu.classList.contains("open")) {
      if (isDesktop) {
        $burgerMenu.style.marginRight = "";
      }
      $header.classList.remove('burger-open');
      $nav.classList.remove('burger-open');
      $body.style.overflow = "";

      document
        .querySelectorAll("a, button")
        .forEach((x) => x.removeAttribute("tabindex"));
    } else {
      if (isDesktop) {
        let $scrollbarWidth =
          ev.view.innerWidth - ev.view.document.body.offsetWidth;
        $burgerMenu.style.marginRight = $scrollbarWidth + "px";
      }

      document
        .querySelectorAll("a, button")
        .forEach((x) => x.setAttribute("tabindex", "-1"));
      document
        .getElementsByClassName("menu-container")[0]
        .querySelectorAll("a, button")
        .forEach((x) => x.removeAttribute("tabindex"));

      $header.classList.add('burger-open');
      $nav.classList.add('burger-open');
      $body.style.overflow = "hidden";
    }

    ev.preventDefault();
    ev.stopPropagation();
  }

  handleKeyPress = (ev) => {
    if (ev.charCode === 13) {
      this.setState({ active: !this.state.active });
    }
  };

  render() {
    return (
      <a
        href={"#0"} /* Use <a> for accessibility Override event click in JS */
        className={this.state.active ? "burger-menu" : "burger-menu open"}
        onClick={this.handleClick}
        onKeyPress={this.handleKeyPress}
        onMouseDown={this.handleMouseDown}
        id={"burger-menu-top"}
      >
        <span />
        <span />
        <span />
        <span>MENU</span>
        <span />
      </a>
    );
  }
}
