import React from "react";
import { Link } from "gatsby";
import logoHorizontalUrl from "src/images/logo_lelivebar_horizontal_min.svg";

/**
 * Composant d'affichage du logo principal
 * @returns {JSX.Element}
 * @constructor
 */

export default function LogoHorizontal() {
  return (
    <div className={"logo-horizontal"}>
      <Link
        to="/"
        title={"Accéder à la page d'accueil"}
      >
          <img src={logoHorizontalUrl} width="406" height="117" alt="" />
      </Link>
    </div>
  );
}
