import React from "react";
import logoFiligraneSVG from "src/images/logo-lelivebar-tete-filigrane.svg";

/**
 * Composant d'affichage du filigrane
 * @returns {JSX.Element}
 * @constructor
 */
export default function Filigrane() {
  return (
    <div className={"logo-filigrane"}>
        <img src={logoFiligraneSVG} width="548" height="583" alt="Logo Le live bar incorporé en filigrane" />
    </div>
  );
}
