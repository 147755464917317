import React from "react";
import Seo from "src/components/seo";
import Header from "src/components/header";
import Filigrane from "src/components/filigrane";
import Footer from "src/components/footer";
import "src/styles/main.scss";
import EngrenageSVG from "src/images/engrenage.svg";
import FeuilleHoublonSVG from "src/images/feuille-houblon.svg";

/**
 * Composant permettant de créer le layout principal du site
 * @param children
 * @param pageProps
 * @returns {JSX.Element}
 * @constructor
 */
export default function LayoutMain({ children, pageProps = {} }) {
  const pagePropsConstructor = {
    title: pageProps.title,
    menuPageTitle: pageProps.menuPageTitle,
    description: pageProps.description,
    className: (typeof pageProps.className !== "undefined") ? pageProps.className : ""
  }

  return (
    <div className={"main " + pagePropsConstructor.className}>
        <Seo seoProps={pagePropsConstructor} />
        <Header pageProps={pagePropsConstructor} />
        <main id={"main"}>
            <Filigrane />
            {children}
        </main>
        <img src={EngrenageSVG} className={"engrenage-background"} alt="Engrenage en décoration du site" />
        <img src={FeuilleHoublonSVG} className={"feuille-houblon-background"} alt="Feuille de houblon en décoration du site" />
        <Footer />
    </div>
  );
}
